import { template as template_927b67d938b3456ab3fccf8d9661ac37 } from "@ember/template-compiler";
const SidebarSectionMessage = template_927b67d938b3456ab3fccf8d9661ac37(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
