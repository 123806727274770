import { template as template_0598efe53dee4960ab66b9b0da4e9fa3 } from "@ember/template-compiler";
const FKControlMenuContainer = template_0598efe53dee4960ab66b9b0da4e9fa3(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
