import { template as template_2200077ee8d44f11ac3eb594ad6d645e } from "@ember/template-compiler";
const WelcomeHeader = template_2200077ee8d44f11ac3eb594ad6d645e(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
